import React, {Fragment} from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import {OpenNewWindowIcon} from './components/Icons';

const About = () => {
  return (
    <Fragment>
      <Navigation />
      <div className="layout-3-to-1 layout-spacing space-above">
        <h2>About Neptú Collective</h2>
        <br /><br />
        <div className="description">
          <div>
            <p>
              Neptú Collective is a collection of international creative businesses. It is made up of a fine art photography & cinematography entity and a wholesale & retail jewellery operation. Our work largely draw inspiration from old school movies, fashion magazines and natural light. Capturing authenticity in relationships - both the relationships between people and the relationships we have with ourselves - is why we do what we do.
            </p>
            <br /><br />
            <h3>Team</h3>
            <p>
              Together with talented professionals in the creative industry, we collaborate and create on a contractual basis. This allows Neptú Collective to not only take on projects that we are confident in delivering with allocated client resources, but also provides utmost flexibility catering to varying geographical or partnership needs. All of our partners are independent business owners. We are accustommed to flowing between joining forces to create together and taking on separate responsibilities in solitude. 
            </p>
            <br />
            {/* <h4>Founder</h4>
            <br />
            <p className="left-indent">
              Hello, my name is Patricia. That's me in the picture. I founded, built and run Neptú Collective as a one-woman show. 
              <br /><br />
              I founded Neptú Collective in my mid-twenties after surrendering to the fact that I will always remain intrinsically curious and involve myself in the intersections of technology, art and business. Being the product of different countries, cultures, languages and lifestyles entwining to form a distinct variation of human existence, I am drawn to depicting that vision in Neptú Collective's work. 
            </p> */}
            <br /><br />
            <p>Feel free to explore and reach out to connect over a cuppa.</p>
          </div>
          {/* <img src="/images/about.jpeg" className="image" alt="" /> */}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default About;
