import React from 'react';

export const OpenNewWindowIcon = () =>
    <svg viewBox="0 0 24 24" className="icon-external">
        <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z" />
    </svg>;

export const ArrowDownIcon = () =>
    <svg viewBox="0 0 150 305" className="icon-arrow-down">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
            <g stroke="#000000" strokeWidth="12.4203822">
                <path d="M6.68789809,231.210191 L75.0006054,296.178344 L143.312102,231.210191 M75,6.68789809 L75,295.22293" />
            </g>
        </g>
    </svg>;

/* Social Media ///////////////////////////////////////////////////
* https://icons8.com/icon/set/social-media/material
*/

export const InstagramIcon = () => 
    <svg viewBox="0 0 18 18" className="icon-social" fill="000000">
        <path d="M5,0 C2.239,0 0,2.239 0,5 L0,13 C0,15.761 2.239,18 5,18 L13,18 C15.761,18 18,15.761 18,13 L18,5 C18,2.239 15.761,0 13,0 L5,0 Z M15,2 C15.552,2 16,2.448 16,3 C16,3.552 15.552,4 15,4 C14.448,4 14,3.552 14,3 C14,2.448 14.448,2 15,2 Z M9,4 C11.761,4 14,6.239 14,9 C14,11.761 11.761,14 9,14 C6.239,14 4,11.761 4,9 C4,6.239 6.239,4 9,4 Z M9,6 C7.34314575,6 6,7.34314575 6,9 C6,10.6568542 7.34314575,12 9,12 C10.6568542,12 12,10.6568542 12,9 C12,7.34314575 10.6568542,6 9,6 Z" />
    </svg>;

export const FacebookIcon = () => 
    <svg viewBox="0 0 20 20" className="icon-social" fill="#000000">
        <path d="M10,0 C4.477,0 0,4.477 0,10 C0,15.013 3.693,19.153 8.505,19.876 L8.505,12.65 L6.031,12.65 L6.031,10.021 L8.505,10.021 L8.505,8.272 C8.505,5.376 9.916,4.105 12.323,4.105 C13.476,4.105 14.085,4.19 14.374,4.229 L14.374,6.523 L12.732,6.523 C11.71,6.523 11.353,7.492 11.353,8.584 L11.353,10.021 L14.348,10.021 L13.942,12.65 L11.354,12.65 L11.354,19.897 C16.235,19.236 20,15.062 20,10 C20,4.477 15.523,0 10,0 Z" />
    </svg>;

// export const LinkedInIcon = () =>
//     <svg viewBox="0 0 18 18" className="icon-social" fill="#000000">
//         <path d="M16,0 L2,0 C0.895,0 0,0.895 0,2 L0,16 C0,17.105 0.895,18 2,18 L16,18 C17.105,18 18,17.105 18,16 L18,2 C18,0.895 17.105,0 16,0 Z M6,14 L3.477,14 L3.477,7 L6,7 L6,14 Z M4.694,5.717 C3.923,5.717 3.408,5.203 3.408,4.517 C3.408,3.831 3.922,3.317 4.779,3.317 C5.55,3.317 6.065,3.831 6.065,4.517 C6.065,5.203 5.551,5.717 4.694,5.717 Z M15,14 L12.558,14 L12.558,10.174 C12.558,9.116 11.907,8.872 11.663,8.872 C11.419,8.872 10.605,9.035 10.605,10.174 C10.605,10.337 10.605,14 10.605,14 L8.082,14 L8.082,7 L10.605,7 L10.605,7.977 C10.93,7.407 11.581,7 12.802,7 C14.023,7 15,7.977 15,10.174 L15,14 Z" />
//     </svg>;

// export const YouTubeIcon = () =>
//     <svg viewBox="0 0 20 16" className="icon-social" fill="#000000">
//         <path d="M19.582,2.186 C19.352,1.326 18.674,0.648 17.814,0.418 C16.254,0 10,0 10,0 C10,0 3.746,0 2.186,0.418 C1.326,0.648 0.648,1.326 0.418,2.186 C0,3.746 0,8 0,8 C0,8 0,12.254 0.418,13.814 C0.648,14.674 1.326,15.352 2.186,15.582 C3.746,16 10,16 10,16 C10,16 16.254,16 17.814,15.582 C18.675,15.352 19.352,14.674 19.582,13.814 C20,12.254 20,8 20,8 C20,8 20,3.746 19.582,2.186 Z M8,11.464 L8,4.536 L14,8 L8,11.464 Z" />
//     </svg>;