import React, {Fragment} from 'react';
import WeddingsNavigation from './WeddingsNavigation';
import Gallery from "react-photo-gallery";
import { TemplatePhotos, TemplateGallery } from '../components/Template';
import {
  polinaJack,
  kullikeMarko,
  janeAlex,
  jelizavetaZanis,
  denitsaKevin,
  kristineBen,
  kristineJarmo,
  irinaMaxim,
  begumVitali,
  ashleyJames,
  teetOwen,
  mariaFranklin,
  sandraGeorg,
  susannaBrendan
} from './WeddingsPhotos';

const WeddingsGallery = () => {
  return (
    <TemplatePhotos
      subNavigation={<WeddingsNavigation />}
      pageCategory="Gallery"
      pageTitle="Wedding Photography & Cinematography"
      instagramHandle="neptu.weddings"
      galleryContent={
        <Fragment>
          <TemplateGallery 
            names="Polina & Jack"
            location="University of Tartu Botanical Gardens, Estonia"
            gallery={<Gallery photos={polinaJack} />}
          />
          <TemplateGallery 
            names="Küllike & Marko"
            location="Tallinna Õpetajate Maja, Estonia"
            gallery={<Gallery photos={kullikeMarko} />}
          />
          <TemplateGallery 
            names="Jane & Alex"
            location="Tartu Maavalitsuse Rahvastikutoimingute Talitus, Estonia"
            gallery={<Gallery photos={janeAlex} />}
          />
          <TemplateGallery 
            names="Jelizaveta & Zanis"
            location="Tartu Kolgata Baptistikogudus, Estonia"
            gallery={<Gallery photos={jelizavetaZanis} />}
          />
          <TemplateGallery 
            names="Denitsa & Kevin"
            location="Villa Mullerbeck, Estonia"
            gallery={<Gallery photos={denitsaKevin} />}
          />
          <TemplateGallery 
            names="Kristine & Ben"
            location="Barabba, Copenhagen"
            gallery={<Gallery photos={kristineBen} />}
          />
          <TemplateGallery 
            names="Kristine & Jarmo"
            location="Tartu, Estonia"
            gallery={<Gallery photos={kristineJarmo} />}
          />
          <TemplateGallery 
            names="Irina & Maxim"
            location="Cantervilla Loss, Estonia"
            gallery={<Gallery photos={irinaMaxim} />}
          />
          <TemplateGallery 
            names="Begüm & Vitali"
            location="Tallinna Perekonnaseisuameti, Estonia"
            gallery={<Gallery photos={begumVitali} />}
          />
          <TemplateGallery 
            names="Ashley & James"
            location="Lake District, United Kingdom"
            gallery={<Gallery photos={ashleyJames} />}
          />
          <TemplateGallery 
            names="Teet & Owen"
            location="Tallinn, Estonia"
            gallery={<Gallery photos={teetOwen} />}
          />
          <TemplateGallery 
            names="Maria & Franklin"
            location="Tartu, Estonia"
            gallery={<Gallery photos={mariaFranklin} />}
          />
          <TemplateGallery 
            names="Sandra & Georg"
            location="Tallinn, Estonia"
            gallery={<Gallery photos={sandraGeorg} />}
          />
          <TemplateGallery 
            names="Susanna & Brendan"
            location="Tallinn, Estonia"
            gallery={<Gallery photos={susannaBrendan} />}
          />
        </Fragment>
      }
    />
  );
};

export default WeddingsGallery;
