import React from 'react';
import { TemplateHome } from '../components/Template';
import WeddingsNavigation from './WeddingsNavigation';

const WeddingsFAQ = () => {
  return (
    <TemplateHome
      subNavigation={ <WeddingsNavigation /> }
      pageCategory="Weddings"
      pageTitle="Frequently Asked Questions"
      instagramHandle="neptu.weddings"
      mainContent={
        <div className="layout-spacing layout-half">
          <div>
            <br />
            <br />

            <h3>Before booking...</h3>

            <h5>01.</h5>
            <h4>Where are you based at and where do you photograph?</h4>
            <p>I am a nomad visiting different parts of the world at different times of the year. I tend to frequent the following countries more than others: EU countries, Singapore and Malaysia. To learn whether I will be where your celebration is, please enquire.</p>
            <br />

            <h5>02.</h5>
            <h4>When should I book your services?</h4>
            <p>The best would be to book me right after you have a date and venue already secured. However, if you would like to ensure that both the venue and my availabilities sync up, feel free to reach out to enquire with the dates of your choice in mind.</p>
            <br />

            <h5>03.</h5>
            <h4>Could we meet you before booking your services?</h4>
            <p>Absolutely! Please reach out and I will be happy to set up a half an hour video call for us to get to know each other more.</p>
            <br />

            <hr />

            <br />
            <h3>Day of celebration...</h3>

            <h5>04.</h5>
            <h4>How will you work on our wedding day?</h4>
            <p>I dress to blend in and photograph with two professional and silent cameras. I'm there with all my gears and backups. Each of my cameras have dual card slots - that prevents any sole reliance on any machine or card. I love working with natural light, but I also bring my flashes with me everywhere I go. Weddings can be overwhelming, so having a reliable and attentive photographer can make a world of difference to the experience and memories.</p>
            <br />

            <h5>05.</h5>
            <h4>Will you show up alone or will you show up with a team?</h4>
            <p>If it's a smaller celebration or shorter hours, I usually work alone. Otherwise, I may opt to include one other team member to ensure that we are able to do our work right. Regardless, I will be in touch let you know so that there won't be any surprises.</p>
            <br />
    
            <h5>06.</h5>
            <h4>What happens if it rains?</h4>
            <p>Rain has never hindered good pictures! I think it would just be the universe's way of giving us a chance to have amazing memories with special effects.</p>
            <br />
          </div>
          <div>
            <br />
            <br />

            <h5>07.</h5>
            <h4>I'm not sure how to pose, what should I do?</h4>
            <p>That's a natural feeling, most of us are not professional models and you have nothing to worry about. When needed, I will be guiding you and the rest will flow naturally. Otherwise, just focus on the people and activities that you enjoy the most!</p>
            <br />

            <h5>08.</h5>
            <h4>What happens if you can't make it to our wedding?</h4>
            <p>Once I book a wedding, I will try my best to be there. That said, certain 'acts of God' like life-threatening illnesses or death would render me quite useless. If it comes to it, you will be informed immediately. I have a trusted community of professionals and team members whom I can turn to for replacing me.</p>
            <br />

            <hr />

            <br />
            <h3>In case you were wondering...</h3>

            <h5>09.</h5>
            <h4>May I have RAW or unedited images?</h4>
            <p>RAW or unedited images are not provided as they do not fully reflect my work. I don't intend to keep your memories hostage, so I make it a point to deliver sans half-blinks, mid-sneezes, bad lighting, accidental shutter presses and repetitions.</p>
            <br />

            <h5>10.</h5>
            <h4>What are your refund and reschedule policies?</h4>
            <p>It will be at my descretion depending on the booking conditions and the situation itself. Let me know your thoughts and I am happy to review our options.</p>
            <br />


            <h5>11.</h5>
            <h4>Why is wedding photography so expensive and may I get a discount?</h4>
            <p>
              On contrary to popular belief, wedding photographers do not just show up, press the shutter button and call it a day. There are a lot going on behind the scenes to bring you professional service and quality photos. You are not hiring someone to press the shutter button, you're purchasing an experience from a professional photographer for one of the most important celebrations in your life. 
              <br /><br />
              In my books, wedding photography is not more expensive than other forms of photography services that I offer. I price my packages snugly for a fair price, so there is no room for discount. That said, I understand that everyone's budget differ so I encourage you to work with vendors who offer price points that align with what you are looking for.
            </p>
            <br />
          </div>
        </div>
      }
    />
  );
};

export default WeddingsFAQ;
