import React from 'react';
import {InstagramIcon, FacebookIcon} from './Icons';

const Footer = () => {
  return (
    <footer>
        <div />
        <span>© 2023 Neptú.co</span>
        <div>
          <a href="https://www.instagram.com/neptu.co" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://www.facebook.com/neptu.co/" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
        </div>
    </footer>
  );
};

export default Footer;
