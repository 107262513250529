import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {OpenNewWindowIcon} from './Icons';

const Navigation = () => {
  return (
    <Fragment>
      <header>
        <div />
        <NavLink to='/' className="logo">
          <img src="/images/logo.png" alt="Logo of Neptú Collective"/>
        </NavLink>
        <div />
      </header>
      <nav>
        <NavLink exact to='/' activeClassName="active-class">
          <span>HOME</span>
        </NavLink>
        <NavLink to='/about' activeClassName="active-class">
          <span>ABOUT</span>
        </NavLink>
        <NavLink to='/commercial' activeClassName="active-class">
          <span>COMMERCIAL</span>
        </NavLink>
        <NavLink to='/weddings' activeClassName="active-class">
          <span>WEDDINGS</span>
        </NavLink>
        <NavLink to='/portraits' activeClassName="active-class">
          <span>PORTRAITS</span>
        </NavLink>
        {/* <a href="https://jewellery.neptu.co" target="_blank" rel="noreferrer">
          <span>JEWELLERY</span>
          <OpenNewWindowIcon />
        </a> */}
        <a href="mailto:hello@neptu.co">
          <span>CONTACT</span>
        </a>
      </nav>
    </Fragment>
  );
};

export default Navigation;
