import React from 'react';
import Gallery from "react-photo-gallery";
import PortraitsNavigation from './PortraitsNavigation';
import {commercialNottingham} from './PortraitsPhotos';
import {TemplatePhotosWithInfo} from '../components/Template';

const PortraitsCommercial = () => {
  return (
    <TemplatePhotosWithInfo 
      // subNavigation={<PortraitsNavigation />}
      pageCategory="Gallery"
      pageTitle="Commercial Photography"
      instagramHandle="neptu.portraits"
      sectionTitle="The Nottingham Lace Garter Company"
      description={
        <p>Heidi, the owner, came to us looking for a collection of commercial photos for the launch of her new business - The Nottingham Lace Garter Company. We set out with planning the art direction to feature an ethereal bridal look and to include some of her lingerie from another line to showcase the garters' range. Working with top creatives in the country, we don the model in a classy, soft and demure look to match the set's white paneling. Heidi was extremely pleased with the final results and delivery. In her words, it turned out exactly like what she had dreamed of.</p>
      }
      details={
        <ul>
          <li><b>Client</b>: <a href="https://thenottinghamlacegartercompany.co.uk/" target="_blank" rel="noreferrer">The Nottingham Lace Garter Company</a></li>
          <li><b>Make-up artist</b>: <a href="https://muamua.ee/" target="_blank" rel="noreferrer">Mua Mua Studio</a></li>
          <li><b>Hairstylist</b>: <a href="https://instagram.com/julialinap_pro" target="_blank" rel="noreferrer">Julia Linap</a></li>
          <li><b>Model agency</b>: <a href="https://almodelmanagement.com/" target="_blank" rel="noreferrer">AL Model Management</a></li>
          <li><b>Model</b>: <a href="https://instagram.com/brittk6rsmaa" target="_blank" rel="noreferrer">Britt Kõrsmaa</a></li>
        </ul>
      }
      gallery={<Gallery photos={commercialNottingham} />}
    />
  );
};

export default PortraitsCommercial;
