import React, {Fragment} from 'react';
import PortraitsNavigation from './PortraitsNavigation';
import {
  sofiyaOlesiaSolomiya,
  helen,
  sofiya,
  ailing,
  irene,
  dion,
  kimberly
} from './PortraitsPhotos';
import Gallery from "react-photo-gallery";
import { TemplatePhotos, TemplateGallery } from '../components/Template';

const PortraitsPeople = () => {
  return (
    <TemplatePhotos
      subNavigation={<PortraitsNavigation />}
      pageCategory="Gallery"
      pageTitle="Portrait Photography"
      instagramHandle="neptu.portraits"
      galleryContent={
        <Fragment>
          <TemplateGallery 
            names="Sofiya, Olesia & Solomiya"
            location="New York City, USA"
            gallery={<Gallery photos={sofiyaOlesiaSolomiya} />}
          />
          <TemplateGallery 
            names="Helen"
            location="Tartu, Estonia"
            gallery={<Gallery photos={helen} />}
          />
          <TemplateGallery 
            names="Sofiya"
            location="Tartu, Estonia"
            gallery={<Gallery photos={sofiya} />}
          />
          <TemplateGallery 
            names="Ai Ling"
            location="London, UK"
            gallery={<Gallery photos={ailing} />}
          />
          <TemplateGallery 
            names="Irene"
            location="London, UK"
            gallery={<Gallery photos={irene} />}
          />
          <TemplateGallery 
            names="Dion"
            location="Batu Pahat, Malaysia"
            gallery={<Gallery photos={dion} />}
          />
          <TemplateGallery 
            names="Kimberly"
            location="New Jersey, USA"
            gallery={<Gallery photos={kimberly} />}
          />
        </Fragment>
      }
    />
  );
};

export default PortraitsPeople;
