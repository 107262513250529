import React from 'react';
import PortraitsNavigation from './PortraitsNavigation';
import {
  sofiyaOlesiaSolomiya
} from './PortraitsPhotos';
import Gallery from "react-photo-gallery";
import { TemplatePhotos, TemplateGallery } from '../components/Template';

const PortraitsEvents = () => {
  return (
    <TemplatePhotos
      subNavigation={<PortraitsNavigation />}
      pageCategory="Gallery"
      pageTitle="Event Photography"
      instagramHandle="neptu.portraits"
      galleryContent={
        <p className="body paragraph-center-reading">Coming soon...</p>
        // <Fragment>
        //   <TemplateGallery 
        //     names="Convertal's 10th Anniversary"
        //     location="Spark Hub, Estonia"
        //     gallery={<Gallery photos={sofiyaOlesiaSolomiya} />}
        //   />
        // </Fragment>
      }
    />
  );
};

export default PortraitsEvents;
