import React, {Fragment} from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';

const Home = () => {
  return (
    <Fragment>
      <Navigation />
      <div className="body paragraph-center-reading">
        <p>
          Hello there, welcome to Neptú Collective.<br />
          Neptú Collective is about documenting powerful moments in the split second of intimate emotions and beauty in unlikely places.
        </p>
        {/* <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Accordion Item Button 1
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                      Accordion Item Button 2
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        In ad velit in ex nostrud dolore cupidatat consectetur
                        ea in ut nostrud velit in irure cillum tempor laboris
                        sed adipisicing eu esse duis nulla non.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion> */}
      </div>
      <Footer />
    </Fragment>
  );
};

export default Home;
